/** JPA Generic Search Term helper
 *  URL: ...../products?term=name:jon,firstname:doe
 *
 * todo escapen?
 *
 *
 * eg: api/masterdata/search?term=Dairy_ident,ContactAttribute_12:hans,ContactAttribute_99:wurst:303&page=0&size=50&sort=localUnitStatus
 *
 */

import { Store, StoreDefinition } from 'pinia'
import { VuexModule } from 'vuex-module-decorators'
import { ref } from 'vue'

export class TermItem {
  key = ''
  value: number | string | null | any = null
  operation = ':'
}

export class TermItemDefinition {
  key = ''
  operation = ':'
  defaultValue?: string | number = '' // not implemented jet
  itemType?: string // boolean, number, string, objects
  isList?: boolean
}

/*
function customTermSaver(store: any, termOrQueryItems: any) {
  //loop over the known attributes and fill the values from the term or query
  store.attributes.map((atrX: any): void => {
    const nameToFind = `ContactAttribute_${atrX.id}`
    atrX.value = termOrQueryItems[nameToFind] ? termOrQueryItems[nameToFind] : null
  })
}
*/

///
export class QueryStyle {
  key = ''
}

export class TERM {
  private static _instance: TERM

  public static get Instance() {
    return this._instance || (this._instance = new this())
  }

  /** From String to object, the result will be treaten equal to this.$route.query */
  public parse(term: string): any {
    const result: { [k: string]: any } = {}
    if (term === undefined || term === '') return
    const props = term.split(',')
    for (let index = 0; index < props.length; index++) {
      const keyValue = props[index].split(/:|~|<=|>=|<|>/)
      result[keyValue[0]] = keyValue[1]
    }
    return result
  }

  /** From term to string */
  public stringify_old(term: any): any {
    let result = ''
    //for (const key in term) {
    for (const [key, value] of Object.entries(term)) {
      if (value === '' || value === undefined || value === null) {
        continue
      }
      if (Object.prototype.hasOwnProperty.call(term, key)) {
        if (result.length) {
          result += ','
        }
        result += key + ':' + term[key]
      }
    }
    return result
  }

  public stringify(term: TermItem[]): any {
    let result = '' // a blank string leads to a assigned variable in the axios call
    //for (const key in term) {
    for (const termItemX of term) {
      if (termItemX.value === '' || termItemX.value === undefined || termItemX.value === null) {
        continue
      }
      if (result.length) {
        result += ','
      }
      if (typeof termItemX.value === 'string' || termItemX.value instanceof String) {
        result += termItemX.key + termItemX.operation + termItemX.value.replace(/,/g, '|')
      } else {
        result += termItemX.key + termItemX.operation + termItemX.value.toString()
      }
    }
    return result
  }

  private customTermBuilder(items: object[], itemName: string) {
    const result: TermItem[] = []
    items.map((atributeX: any) => {
      if (atributeX.value) {
        const tItem: TermItem = { key: itemName + '_' + atributeX.id, value: atributeX.value, operation: ':' }
        result.push(tItem)
      }
    })
    return result
  }

  // creates a TermItem[] object wich you can spread into your params
  // code___ query{...buildTermItems(this.$store.state.SearchSubsidyGroupingStore, this.$store.state.SearchSubsidyGroupingStore.items)}
  public buildTermItems(store: any, items: TermItemDefinition[]): TermItem[] {
    if (!store?.useTerms === true) {
      console.error(`you call buildTermItems of a non Term store ` + JSON.stringify(store))
    }
    let result: TermItem[] = []
    for (const item of items) {
      if (item.itemType == 'objects') {
        const test = store[item.key]
        result = result.concat(this.customTermBuilder(store[item.key], item.key))
      } else {
        result.push({ key: item.key, value: store[item.key], operation: item.operation })
      }
    }
    return result
  }
}

export const Term = TERM.Instance
